<template>
  <div class="elements">
    <div class="page-label"><b>Account: </b><span>{{accountInfo.accountName}} ({{accountInfo.accountId}})</span></div>
    <div v-if="campaignPivot.campaigns > 0" class="budget-type-pivot">
      <div class="title-sticky">
        <label class="element-label">Campaigns</label>
        <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
          <label class="checkbox-status-label">
            {{ budgetTypeVerified ? "Verified" : "Check to verify" }}
            <input type="checkbox" v-model="budgetTypeVerified" />
          </label>
        </span>
      </div>
      <div class="budget-type-table">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th
                v-for="item in adwordsEnums.budgetTypeColumns"
                :key="item"
              >
                {{ (isPerformanceMaxCampaign || isDisplayCampaign || isAppCampaign || isDemandGenCampaign) && item == "Negative Keyword" ? showColumnsText : item }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(record, i) in campaignPivot.records" >
            <tr  class="campaign-brief-row" :key="i" @click="toggleAdgroup(i)">
              <td>{{ record.campaignName }}</td>
              <td class="data-center upper-case">
                {{ record.status }}
              </td>
              <td class="data-center upper-case">
                {{ record.campaignType }}
              </td>
              <td class="data-center">
                {{ record.bpCurrency }} {{ record.totalBudget }}
              </td>
              <td class="campaign-brief-row" width="150">
                <span>{{ record.bidStrategyType }}</span>
                <br>
                <Row-Col span="16">
                  <span v-if="bid[record.newIdentifier] || isBidEditEnabled[record.newIdentifier]">{{ record.bpCurrency }} </span>
                  <i-input v-model="tempBid[record.newIdentifier]" type="number" size="small" class="edit-bid-input"
                    @on-change="validateInput" v-if="isBidEditEnabled[record.newIdentifier] && isSubmission" />
                  <span v-else-if="bid[record.newIdentifier]">{{ bid[record.newIdentifier] }}</span>
                  <span v-else>NA</span>
                </Row-Col>
                <Row-Col span="8" class="text-right" v-if="record.id == 0 && record.isBidSupported && isSubmission">
                  <i class="fa fa-pencil" @click="enableBidEdit(record.newIdentifier)"
                    v-if="!isBidEditEnabled[record.newIdentifier]" />
                  <Icon v-if="isBidEditEnabled[record.newIdentifier]" class="campaign-row-icon" type="md-close"
                    @click="cancelBid(record.newIdentifier)" />
                  <Icon v-if="isBidEditEnabled[record.newIdentifier]" class="campaign-row-icon" type="md-checkmark"
                    @click="applyBid(record.newIdentifier)" />
                </Row-Col>
              </td>
              <td class="data-center" v-if="isPerformanceMaxCampaign">
                {{ record.assetGroups.length }}
              </td>
              <td class="data-center" v-else-if="isDisplayCampaign || isAppCampaign || isDemandGenCampaign">
                {{ record.adgroups.length }}
              </td>
              <td class="data-center" v-else>
                {{ getNegativeKeywordsCount(record.isNegativeKeywordExported,record.negativeKeywordListIds)}}
              </td>
            </tr>
            <tr :key="i+'adgroup'" class="adgroup-brief-row" v-if="expandedCampaignRows.includes(i) && record.adgroups.length > 0" >
              <td colspan="5">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th v-for="adgItem in showAdGroupsColumns" :key="adgItem">
                          {{ adgItem }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(adgroup, adgroupIndex) in record.adgroups">
                    <tr class="adgroup-brief-row" :key="adgroupIndex"  @click="toggleAd(adgroupIndex+adgroup.name)">
                      <td>{{ adgroup.name }}</td>
                      <td class="data-center upper-case">
                      {{ adgroup.ads.length }}
                      </td>
                      <td v-if="!isDisplayCampaign && !isAppCampaign && !isDemandGenCampaign" class="data-center upper-case">
                      {{ adgroup.keywords.length }}
                      </td>
                      <td v-if="!isDisplayCampaign && !isAppCampaign  && !isDemandGenCampaign" class="data-center">
                      {{ adgroup.adExtensions }}
                      </td>
                    </tr>
                    <tr :key="adgroupIndex+'ad'" class="ad-preview-row" v-if="expandedadgroupRows.includes(adgroupIndex+adgroup.name) && adgroup.ads.length > 0 && !isDisplayCampaign && !isAppCampaign && !isDemandGenCampaign" >
                      <td colspan="4">
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th v-for="adItem in adwordsEnums.adsColumns" :key="adItem">
                                {{ adItem }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(ad, adIndex) in adgroup.ads" :key="adIndex">
                              <td>{{ad.ad}}</td>
                              <td>{{ad.landingPage}}</td>
                              <td>{{ad.displayUrl}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    </template>
                  </tbody>
                </table>
              </td>
            </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="targetsPivot.length > 0" class="targets-pivot">
      <div class="title-sticky">
        <label class="element-label">Targeting</label>
        <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
          <label class="checkbox-status-label">
            {{ targetsVerified ? "Verified" : "Check to verify" }}
            <input type="checkbox" v-model="targetsVerified" />
          </label>
        </span>
      </div>
      <div class="targets-table">
        <table class="table table-bordered">
          <thead>
            <th
              v-for="item in adwordsEnums.targetColumns"
              :key="item"
            >
              {{ item }}
            </th>
          </thead>
          <tbody>
            <tr v-for="(target, targetIndex) in targetsPivot" :key="targetIndex">
              <td >
                <div>
                  <label class="target-label">
                      {{target.name}}
                  </label>
                  <div class="target-sub-label">
                    <i>Languages: {{target.languages}}</i>
                    <br>
                    <i>Age: {{target.ages}}</i>
                    <br>
                    <i>Gender: {{target.genders}}</i>
                    <br>
                    <i>Household Incomes: {{target.householdIncomes}}</i>
                    <i v-if="target.targetingMethod"><br>Targeting Method: {{target.targetingMethod}}</i>
                    <i v-if="target.interests"><br>Interests: {{target.interests}}</i>
                    <i v-if="target.customInterests"><br>Custom Interests: {{target.customInterests}}</i>
                    <i v-if="target.firstPartyAudiences"><br>First Party Audiences: {{target.firstPartyAudiences}}</i>
                  </div>
                </div>
              </td>
              <td class="data-right" v-if="isPerformanceMaxCampaign">
                {{ target.campaigns }} ( {{ target.assetGroups}} Asset Groups)
              </td>
              <td class="data-right" v-else>
                {{ target.campaigns }} ( {{ target.adgroups}} Adgroups)
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="locationsPivot.length > 0" class="locations-pivot">
      <div class="title-sticky">
        <label class="element-label">Locations</label>
        <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
          <label class="checkbox-status-label">
            {{ locationsVerified ? "Verified" : "Check to verify" }}
            <input type="checkbox" v-model="locationsVerified" />
          </label>
        </span>
      </div>
      <div class="locations-table">
        <table class="table table-bordered">
          <thead>
            <th
              v-for="item in adwordsEnums.locationColumns"
              :key="item"
            >
              {{ item }}
            </th>
          </thead>
          <tbody>
            <tr v-for="(locObj, index) in locationsPivot" :key="index">
              <td>{{ locObj.location }}</td>
              <td class="data-right" v-if="isPerformanceMaxCampaign">
                {{ locObj.campaigns }} ( {{ locObj.assetGroup}} Asset Groups)
              </td>
              <td class="data-right" v-else>
                {{ locObj.campaigns }} ( {{ locObj.adgroups}} Adgroups)
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="excludedLocationsPivot.length > 0" class="locations-pivot">
      <div class="title-sticky">
        <label class="element-label">Excluded Locations</label>
        <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
          <label class="checkbox-status-label">
            {{ excludedLocationsVerified ? "Verified" : "Check to verify" }}
            <input type="checkbox" v-model="excludedLocationsVerified" />
          </label>
        </span>
      </div>
      <div class="locations-table">
        <table class="table table-bordered">
          <thead>
            <th
              v-for="item in adwordsEnums.locationColumns"
              :key="item"
            >
              {{ item }}
            </th>
          </thead>
          <tbody>
            <tr v-for="(locObj, index) in excludedLocationsPivot" :key="index">
              <td>{{ locObj.location }}</td>
              <td class="data-right" v-if="isPerformanceMaxCampaign">
                {{ locObj.campaigns }} ( {{ locObj.assetGroup}} Asset Groups)
              </td>
              <td class="data-right" v-else>
                {{ locObj.campaigns }} ( {{ locObj.adgroups}} Adgroups)
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="showNetworkSummary" class="networks-pivot">
      <div class="title-sticky">
        <label class="element-label">Networks</label>
        <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
          <label class="checkbox-status-label">
            {{ networksVerified ? "Verified" : "Check to verify" }}
            <input type="checkbox" v-model="networksVerified" />
          </label>
        </span>
      </div>
      <div class="networks-table">
        <table class="table table-bordered">
          <thead>
            <th
              v-for="item in adwordsEnums.networksColumns"
              :key="item"
            >
              {{ item }}
            </th>
          </thead>
          <tbody>
            <tr>
              <td>
                <span v-if="networksPivot.googleDisplayNetworkPresent">All</span>
                <span v-else>-</span>
              </td>
              <td>
                <span v-if="networksPivot.searchNetworkPresent">All</span>
                <span v-else>-</span>
              </td>
              <td class="data-right">
                {{ networksPivot.campaigns }} ( {{ networksPivot.adgroups}} Adgroups)
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="assetGroupsPivot.length > 0" class="budget-type-pivot">
      <div class="title-sticky">
        <label class="element-label">{{ showGroupsText }}</label>
        <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
          <label class="checkbox-status-label">
            {{ assetGroupsVerified ? "Verified" : "Check to verify" }}
            <input type="checkbox" v-model="assetGroupsVerified" />
          </label>
        </span>
      </div>
      <div class="budget-type-table">
        <table class="table table-bordered">
          <thead>
            <tr v-if="isDisplayCampaign || isDemandGenCampaign">
              <th
                v-for="item in adwordsEnums.displayAdGroupColumns"
                :key="item"
              >
                {{ item }}
              </th>
            </tr>
            <tr v-else-if="isAppCampaign">
              <th
                v-for="item in adwordsEnums.appAdGroupColumns"
                :key="item"
              >
                {{ item }}
              </th>
            </tr>
            <tr v-else>
              <th
                v-for="item in adwordsEnums.assetGroupColumns"
                :key="item"
              >
                {{ item }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(record, i) in assetGroupsPivot">
              <tr class="campaign-brief-row" :key="'asg'+i">
                <td v-if="isDisplayCampaign" class="data-center" :rowspan="getRowSpanAssetGroupTable(record)">
                  <span class="adName">
                    {{ record.name }}
                  </span>
                  <span class="adName extraAdName" v-if="record.headlines.length > 3">
                    &nbsp;+{{ record.headlines.length - 3 }} more
                  </span>
                  ({{ record.adgroupName }})
                </td>
                <td v-else-if="isAppCampaign" class="data-center" :rowspan="getRowSpanAssetGroupTable(record)">
                    <span class="adName">
                      {{ record.name }}
                    </span>
                    <span class="adName extraAdName" v-if="record.headlines.length > 3">
                      &nbsp;+{{ record.headlines.length - 3 }} more
                    </span>
                    ({{ record.adgroupName }})
                </td>
                <td v-else class="data-center" :rowspan="getRowSpanAssetGroupTable(record)">{{ record.name }}</td>
              </tr>
              <tr class="campaign-brief-row" v-if="isPerformanceMaxCampaign">
                <td class="data-center" :rowspan="getRowSpanAssetGroupTable(record)">{{ adwordsEnums.entityStatus[record.status] }}</td>
              </tr>
              <tr class="campaign-brief-row" v-if="showFinalUrl">
                <td class="data-center upper-case" v-if="isAppCampaign">App Url</td>
                <td class="data-center upper-case" v-else>Final Url</td>
                <td>
                  {{ record.finalUrl }}
                </td>
              </tr>
              <tr class="campaign-brief-row">
                <td class="data-center upper-case">Headline</td>
                <td>
                  {{ record.headlines.join(', ') }}
                </td>
              </tr>
              <tr class="campaign-brief-row" v-if="!isAppCampaign">
                <td class="data-center upper-case">Long Headline</td>
                <td>
                  <span v-if="record.longHeadlines.length">{{ record.longHeadlines.join(', ') }}</span>
                  <span v-else>{{ '-' }}</span>
                </td>
              </tr>
              <tr class="campaign-brief-row">
                <td class="data-center upper-case">Description</td>
                <td>
                  {{ record.descriptions.join(', ') }}
                </td>
              </tr>
              <tr class="campaign-brief-row" v-if="!isAppCampaign">
                <td class="data-center upper-case">Business Name</td>
                <td>
                  {{ record.businessName }}
                </td>
              </tr>
              <tr class="campaign-brief-row" v-if="isPerformanceMaxCampaign && record.callToAction && record.callToAction">
                <td class="data-center upper-case">Call to Action</td>
                <td>
                  <span>{{ record.callToAction }}</span>
                </td>
              </tr>
              <tr class="campaign-brief-row" v-if="isPerformanceMaxCampaign && record.searchThemes && record.searchThemes.length">
                <td class="data-center upper-case">Search Themes</td>
                <td>
                  <span>{{ record.searchThemes.join(', ') }}</span>
                </td>
              </tr>
              <tr class="campaign-brief-row" v-if="!isAppCampaign">
                <td class="data-center upper-case">Images</td>
                <td>
                  <span v-for="(image, j) in record.logos" class="image-asset" :key="'lg'+j"><img v-lazy="image" height="50"></span>
                  <span v-for="(image, j) in record.landscapeLogos" class="image-asset" :key="'llg'+j"><img v-lazy="image" height="50"></span>
                  <span v-for="(image, j) in record.marketingImages" class="image-asset" :key="'mi'+j"><img v-lazy="image" height="50"></span>
                  <span v-for="(image, j) in record.squareLogoImages" class="image-asset" :key="'sli'+j"><img v-lazy="image" height="50"></span>
                  <span v-for="(image, j) in record.squareMarketingImages" class="image-asset" :key="'smi'+j"><img v-lazy="image" height="50"></span>
                  <span v-for="(image, j) in record.portraitMarketingImages" class="image-asset" :key="'pi'+j"><img v-lazy="image" height="50"></span>
                </td>
              </tr>
              <tr class="campaign-brief-row" v-if="isAppCampaign && record.images.length">
                <td class="data-center upper-case">Images</td>
                <td>
                  <span v-for="(image, j) in record.images" class="image-asset" :key="'lg'+j"><img v-lazy="image" height="50"></span>
                </td>
              </tr>
              <tr class="campaign-brief-row" v-if="record.youtubeVideos && record.youtubeVideos.length > 0">
                <td class="data-center upper-case">Videos</td>
                <td>
                  <span v-for="(video, j) in record.youtubeVideos" class="image-asset" :key="'yv'+j"><iframe :src="getEmbedYoutubeLink(video)" width="200" height="150"></iframe><br></span>
                </td>
              </tr>
              <template v-if="isDemandGenCampaign && record.cardsInfo && record.cardsInfo.length">
                <tr class="campaign-brief-row" v-for="(card, index) in record.cardsInfo" :key="index">
                <td class="data-center upper-case">Card {{ index + 1 }}</td>
                <td>
                  <span v-for="(image, j) in card.images" class="image-asset" :key="'lg'+j"><img v-lazy="image" height="50"></span><br><br>
                  <span class="card-text">Headline: </span>{{ card.headline }}<br>
                  <span class="card-text">Final URL: </span>{{ card.finalUrl }}<br>
                  <span v-if="card.callToAction" class="card-text">Call to action: </span>{{ card.callToAction }}
                </td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { locale, input, Icon, Col } from "iview";
import lang from "iview/dist/locale/en-US";
import { mapState, mapMutations } from "vuex";
import { adwordsEnums } from "../../Constants/AdwordsEnums"
import { APIService } from "../../ApiService.js";
import VueLazyload from 'vue-lazyload'

locale(lang); // iview is default chinese
Vue.use(VueLazyload, {
  attempt: 1
})

export default {
  props: {
    isSubmission: { default: true },
    viewType: { default: "Changes" }
  },
  components: {
    "i-input": input,
    Icon,
    "Row-Col": Col
  },
  created () {
    if (!this.isCampaignLauncherVerificationEnabled) {
      this.budgetTypeVerified = true
      this.targetsVerified = true
      this.locationsVerified = true
      this.excludedLocationsVerified = true
      this.networksVerified = true
      this.adsVerified = true
      this.assetGroupsVerified = true
      this.verifyAll()
    }
    if (this.campaignPivot) {
      this.expandedCampaignRows = [];
      for (let i = 0; i < this.campaignPivot.campaigns; i++) {
        this.expandedCampaignRows.push(i);
      }
    }
    APIService.getLanguages()
      .then(response => {
        if (response && response.data) {
          this.languages = [...new Set(response.data.map(function (e) {
            return { value: e.googleCode, label: e.displayName };
          }))].filter(e => e.value);
        }
      })
      .catch(_ => {
        this.$Message.error({
          background: true,
          content: "Failed to fetch languages"
        });
      });
  },
  data: function () {
    return {
      budgetTypeVerified: true,
      targetsVerified: true,
      locationsVerified: true,
      excludedLocationsVerified: true,
      networksVerified: true,
      assetGroupsVerified: true,
      adwordsEnums: adwordsEnums,
      expandedCampaignRows: [],
      expandedadgroupRows: [],
      languages: [],
      bid: {},
      tempBid: {},
      isBidEditEnabled: {},
      accountInfo: {
        accountId: "",
        accountName: ""
      }
    };
  },
  computed: {
    ...mapState([
      "adwordsEntitiesResponse",
      "deltax",
      "adwordsLocations",
      "adwordsTargets",
      "isCampaignLauncherVerificationEnabled",
      "dbStores",
      "selectedCampaignLauncherConfig",
      "editedCampaignBid",
      "googleAccounts"
    ]),
    campaignPivot: function () {
      if (this.isCampaignLauncherVerificationEnabled) {
        this.budgetTypeVerified = false;
      }

      if (JSON.stringify(this.adwordsEntitiesResponse) === "{}") return {}

      const adwordsCampaigns = JSON.parse(JSON.stringify(this.adwordsEntitiesResponse.campaigns));
      // set account info
      let selectedAccount = this.googleAccounts.find(account => account.Id == this.selectedCampaignLauncherConfig.bpseId);
      this.accountInfo.accountId = selectedAccount ? selectedAccount.CustomerId : "";
      this.accountInfo.accountName = selectedAccount ? selectedAccount.NickName : "";

      let records = adwordsCampaigns.map(campaign => {
        let record = {
          id: campaign.id,
          internalId: campaign.internalId,
          newIdentifier: campaign.newIdentifier,
          campaignName: campaign.name,
          campaignType: adwordsEnums.advertisingChannelType[campaign.advertisingChannelType],
          totalBudget: campaign.budget.toLocaleString(),
          status: adwordsEnums.entityStatus[campaign.status],
          bpCurrency: this.deltax.bpCurrencyCode,
          bidStrategyType: adwordsEnums.adwordsBiddingStrategies.find(bidStrategyType => bidStrategyType.id == campaign.bidStrategyType).name,
          isBidSupported: this.isBidSupported(campaign.bidStrategyType),
          adgroups: this.adwordsAdgroups(campaign.adgroups),
          assetGroups: this.adwordsAssetGroups(campaign.assetGroups),
          negativeKeywordListIds: campaign.negativeKeywordListIds,
          isNegativeKeywordExported: campaign.isNegativeKeywordExported
        }
        if (record.isBidSupported) {
          this.bid[record.newIdentifier] = this.getCampaignBid(campaign)
        }

        return record;
      });

      if (this.viewType == "Changes") {
        // keep only which are not published / had any unpublished subordinate
        records = records.filter((r) => !r.id || r.adgroups.length > 0 || r.assetGroups.length);
      }
      let totalCampaigns = records.length;
      if (!totalCampaigns) {
        this.budgetTypeVerified = true;
      }
      return { records: records, campaigns: totalCampaigns };
    },
    targetsPivot: function () {
      if (this.isCampaignLauncherVerificationEnabled) {
        this.targetsVerified = false;
      }
      const adwordsCampaigns = this.adwordsCampaigns();
      var targetsRecord = {}
      adwordsCampaigns.forEach(campaign => {
        if (targetsRecord[campaign.targetId]) {
          targetsRecord[campaign.targetId]["campaigns"] += 1
          targetsRecord[campaign.targetId]["adgroups"] += campaign.adgroups.length
        } else {
          var targets = this.adwordsTargets.filter(t => t.id == campaign.targetId)
          let self = this;
          if (targets.length > 0) {
            var target = targets[0];
            target["targetJson"] = JSON.parse(target.targetString)
            var ages = target.targetJson.AgeRanges.map(ar => adwordsEnums.ageRange[ar]).join(",")
            var householdIncomes = target.targetJson.HouseholdIncomeRanges.map(hr => adwordsEnums.houseHoldIncome[hr]).join(",")
            var genders = target.targetJson.Genders.map(g => adwordsEnums.gender[g]).join(",")
            var languages = []
            if (self.languages.length > 0) {
              languages = target.targetJson.LanguageCodes.map(lan => self.languages.find(l => l.value == lan).label).join(",")
            }
            var interests = "";
            var customInterests = "";
            var firstPartyAudiences = "";
            if (target.dynamicTargetString) {
              target["dynamicTargetJson"] = JSON.parse(target.dynamicTargetString)
              if (target.dynamicTargetJson.Interests && target.dynamicTargetJson.Interests.length) {
                interests = target.dynamicTargetJson.Interests.join(",")
              }
              if (target.dynamicTargetJson.CustomInterests && target.dynamicTargetJson.CustomInterests.length) {
                customInterests = target.dynamicTargetJson.CustomInterests.join(",")
              }
              if (target.dynamicTargetJson.FirstPartyAudiences && target.dynamicTargetJson.FirstPartyAudiences.length) {
                firstPartyAudiences = target.dynamicTargetJson.FirstPartyAudiences.join(",")
              }
            }
            var targetingMethod = "";
            if (target.targetJson.TargetingMethod) {
              targetingMethod = adwordsEnums.targetingMethod[target.targetJson.TargetingMethod]
            }
            targetsRecord[campaign.targetId] = {
              name: target.name,
              ages,
              householdIncomes,
              genders,
              campaigns: 1,
              adgroups: campaign.adgroups.length,
              assetGroups: campaign.assetGroups.length,
              languages: languages,
              interests,
              customInterests,
              firstPartyAudiences,
              targetingMethod
            }
          }
        }
      });
      targetsRecord = Object.values(targetsRecord)
      if (!targetsRecord.length) {
        this.targetsVerified = true;
      }
      return targetsRecord
    },
    locationsPivot: function () {
      if (this.isCampaignLauncherVerificationEnabled) {
        this.locationsVerified = false;
      }
      const adwordsCampaigns = this.adwordsCampaigns();
      var locationsRecord = {}
      adwordsCampaigns.forEach(campaign => {
        var self = this;
        let includedlocationCodes = []
        if (this.isDemandGenCampaign) {
          campaign.adgroups.forEach(adgrp => {
            includedlocationCodes = includedlocationCodes.concat(adgrp.targeting.locations.included.locationCodes);
          });
        } else {
          includedlocationCodes = campaign.targeting.locations.included.locationCodes;
        }
        includedlocationCodes.forEach(locationCode => {
          if (locationsRecord[locationCode]) {
            locationsRecord[locationCode]["campaigns"] += 1
            locationsRecord[locationCode]["adgroups"] += campaign.adgroups.length
            locationsRecord[locationCode]["assetGroup"] += campaign.assetGroups.length
          } else {
            var locations = []
            if (self.selectedCampaignLauncherConfig && self.selectedCampaignLauncherConfig.campaign.locationMode == adwordsEnums.locationMode.Hyperlocal) {
              locations = self.dbStores.filter(l => l.storeCode == locationCode)
              if (locations.length > 0) {
                if (locations[0]["additionalLocations"]) {
                  var additionalLocations = this.formatLocations(locations[0]["additionalLocations"])
                }
                var radius = `(${locations[0]["radius"]} ${adwordsEnums.distanceUnit[locations[0]["radiusUnit"]]} radius)`
                locationsRecord[locationCode] = {
                  campaigns: 1,
                  adgroups: campaign.adgroups.length,
                  assetGroup: campaign.assetGroups.length,
                  location: `${locations[0]["name"]} (${locations[0]["storeCode"]}): (latitude:${locations[0]["latitude"]}, longitude:${locations[0]["longitude"]}) ${radius}`
                }
                if (additionalLocations) {
                  locationsRecord[locationCode]["location"] = `${locationsRecord[locationCode]["location"]} ${additionalLocations}`
                }
              }
            } else {
              locations = self.adwordsLocations.filter(l => l.googleCode == locationCode)
              if (locations.length > 0) {
                locationsRecord[locationCode] = {
                  campaigns: 1,
                  adgroups: campaign.adgroups.length,
                  assetGroup: campaign.assetGroups.length,
                  location: `${locations[0]["completePath"]} (${locations[0]["googleCode"]})`
                }
              }
            }
          }
        });
      });
      locationsRecord = Object.values(locationsRecord)
      if (!locationsRecord.length) {
        this.locationsVerified = true;
      }
      return locationsRecord
    },
    excludedLocationsPivot: function () {
      if (this.isCampaignLauncherVerificationEnabled) {
        this.excludedLocationsVerified = false;
      }
      const adwordsCampaigns = this.adwordsCampaigns();
      var locationsRecord = {}
      adwordsCampaigns.forEach(campaign => {
        var self = this;
        let excludedlocationCodes = []
        if (this.isDemandGenCampaign) {
          campaign.adgroups.forEach(adgrp => {
            excludedlocationCodes = excludedlocationCodes.concat(adgrp.targeting.locations.excluded.locationCodes);
          });
        } else {
          excludedlocationCodes = campaign.targeting.locations.excluded.locationCodes;
        }
        excludedlocationCodes.forEach(locationCode => {
          if (locationsRecord[locationCode]) {
            locationsRecord[locationCode]["campaigns"] += 1
            locationsRecord[locationCode]["adgroups"] += campaign.adgroups.length
            locationsRecord[locationCode]["assetGroup"] += campaign.assetGroups.length
          } else {
            var locations = []
            locations = self.adwordsLocations.filter(l => l.googleCode == locationCode)
            if (locations.length > 0) {
              locationsRecord[locationCode] = {
                campaigns: 1,
                assetGroup: campaign.assetGroups.length,
                location: `${locations[0]["completePath"]} (${locations[0]["googleCode"]})`
              }
            }
          }
        });
      });
      locationsRecord = Object.values(locationsRecord)
      if (!locationsRecord.length) {
        this.excludedLocationsVerified = true;
      }
      return locationsRecord
    },
    networksPivot: function () {
      if (this.isCampaignLauncherVerificationEnabled && this.selectedCampaignLauncherConfig.campaign.type == this.adwordsEnums.campaignType.Search) {
        this.networksVerified = false;
      }
      const adwordsCampaigns = this.adwordsCampaigns();
      if (!adwordsCampaigns.length) {
        this.networksVerified = true;
        return { campaigns: 0 }
      }
      var adgroups = 0;
      adwordsCampaigns.forEach(ae => {
        adgroups += ae.adgroups.length
      })
      var adwordsEntity = adwordsCampaigns[0]
      var googleDisplayNetworkPresent = adwordsEntity.networkSettings.includes(Number(this.findByValue(adwordsEnums.campaignNetworks, "Google Display Network")))
      var searchNetworkPresent = adwordsEntity.networkSettings.includes(Number(this.findByValue(adwordsEnums.campaignNetworks, "Search Network")))
      return { googleDisplayNetworkPresent, searchNetworkPresent, campaigns: adwordsCampaigns.length, adgroups }
    },
    assetGroupsPivot: function () {
      if (this.isCampaignLauncherVerificationEnabled) {
        this.assetGroupsVerified = false;
      }
      var assetGroupsRecord = []
      if (this.campaignPivot.records.length > 0 && this.isDisplayCampaign) {
        let adwordsAdgroups = []
        this.adwordsEntitiesResponse.campaigns.forEach(campaign => {
          adwordsAdgroups.push(...campaign.adgroups);
        })
        adwordsAdgroups.forEach(adgrp => {
          let adGrpIdentifier = adgrp.name
          adgrp.ads.forEach(ad => {
            let Ad = {}
            Ad.id = ad.id
            Ad.name = ad.name
            Ad.adgroupName = adGrpIdentifier
            Ad.finalUrl = ad.finalUrls[0]
            Ad.headlines = ad.headlines.map((headline) => headline.text)
            Ad.longHeadlines = [ad.longHeadline.text]
            Ad.descriptions = ad.descriptions.map((desc) => desc.text)
            Ad.businessName = ad.businessName
            Ad.youtubeVideos = ad.youtubeVideos
            Ad.logos = ad.logoImages
            Ad.marketingImages = ad.marketingImages
            Ad.squareLogoImages = ad.squareLogoImages
            Ad.squareMarketingImages = ad.squareMarketingImages
            Ad.processingStatus = ad.processingStatus
            assetGroupsRecord.push(Ad)
          })
        });
        assetGroupsRecord = this.adwordsAssetGroups(assetGroupsRecord);
      } else if (this.campaignPivot.records.length > 0 && this.isAppCampaign) {
        let adwordsAdgroups = []
        this.adwordsEntitiesResponse.campaigns.forEach(campaign => {
          adwordsAdgroups.push(...campaign.adgroups);
        })
        adwordsAdgroups.forEach(adgrp => {
          let adGrpIdentifier = adgrp.name
          adgrp.ads.forEach(ad => {
            let Ad = {}
            Ad.id = ad.id
            Ad.name = ad.name
            Ad.adgroupName = adGrpIdentifier
            Ad.headlines = ad.headlines.map((headline) => headline.text)
            Ad.descriptions = ad.descriptions.map((desc) => desc.text)
            Ad.youtubeVideos = ad.youtubeVideos
            Ad.images = ad.images
            Ad.processingStatus = ad.processingStatus
            // add final url if subtype is engagement
            if (this.selectedCampaignLauncherConfig.campaign.type == adwordsEnums.campaignType["App"] && this.selectedCampaignLauncherConfig.campaign.subtype == adwordsEnums.campaignSubType["App Engagement"]) {
              Ad.finalUrl = ad.finalAppUrls[0]
            }
            assetGroupsRecord.push(Ad)
          })
        });
        assetGroupsRecord = this.adwordsAssetGroups(assetGroupsRecord);
      } else if (this.campaignPivot.records.length > 0 && this.isDemandGenCampaign) {
        let adwordsAdgroups = []
        this.adwordsEntitiesResponse.campaigns.forEach(campaign => {
          adwordsAdgroups.push(...campaign.adgroups);
        })
        adwordsAdgroups.forEach(adgrp => {
          let adGrpIdentifier = adgrp.name
          adgrp.ads.forEach(ad => {
            let Ad = {}
            Ad.id = ad.id
            Ad.name = ad.name
            Ad.adgroupName = adGrpIdentifier
            Ad.finalUrl = ad.finalUrls[0]
            Ad.headlines = ad.headlines.map((headline) => headline.text)
            if (ad.longHeadlines) {
              Ad.longHeadlines = ad.longHeadlines.map((headline) => headline.text)
            } else {
              Ad.longHeadlines = ad.longHeadline
            }
            Ad.descriptions = ad.descriptions.map((desc) => desc.text)
            Ad.businessName = ad.businessName
            Ad.youtubeVideos = ad.youtubeVideos
            Ad.logos = ad.logoImages
            Ad.marketingImages = ad.marketingImages
            Ad.squareLogoImages = ad.squareLogoImages
            Ad.squareMarketingImages = ad.squareMarketingImages
            let cards = []
            if (ad.cardAssets) {
              ad.cardAssets.forEach((asset) => {
                let card = {}
                card.headline = asset.headline
                card.finalUrl = asset.finalUrls[0]
                card.callToAction = asset.callToActionText
                let imageProps = ['marketingImageAsset', 'squareMarketingImageAsset', 'portraitMarketingImageAsset']
                card.images = []
                imageProps.forEach((property) => {
                  if (asset[property]) {
                    card.images.push(asset[property]);
                  }
                })
                cards.push(card);
              })
            }
            Ad.cardsInfo = cards
            Ad.processingStatus = ad.processingStatus
            assetGroupsRecord.push(Ad)
          })
        });
        assetGroupsRecord = this.adwordsAssetGroups(assetGroupsRecord);
      } else {
        var adwordsCampaigns = this.adwordsEntitiesResponse.campaigns;
        adwordsCampaigns.forEach(campaign => {
          let assetIds = assetGroupsRecord.map(asg => asg.identifier).join(',');
          assetGroupsRecord = [...assetGroupsRecord, ...this.adwordsAssetGroups(campaign.assetGroups).filter(asg => asg.id != 0 || !assetIds.includes(asg.identifier))]
        })
      }
      if (!assetGroupsRecord.length) {
        this.assetGroupsVerified = true;
      }
      return assetGroupsRecord
    },
    showNetworkSummary () {
      return this.networksPivot.campaigns > 0 && this.selectedCampaignLauncherConfig && this.selectedCampaignLauncherConfig.campaign.type == this.adwordsEnums.campaignType.Search
    },
    isPerformanceMaxCampaign: function () {
      return this.selectedCampaignLauncherConfig.campaign.type == this.adwordsEnums.campaignType["Performance Max"];
    },
    isDisplayCampaign: function () {
      return this.selectedCampaignLauncherConfig.campaign.type == this.adwordsEnums.campaignType["Display"];
    },
    isAppCampaign: function () {
      return this.selectedCampaignLauncherConfig.campaign.type == this.adwordsEnums.campaignType["App"];
    },
    isSearchCampaign: function () {
      return this.selectedCampaignLauncherConfig.campaign.type == this.adwordsEnums.campaignType["Search"];
    },
    isDemandGenCampaign: function () {
      return this.selectedCampaignLauncherConfig.campaign.type == this.adwordsEnums.campaignType["Demand Gen"];
    },
    showGroupsText: function () {
      return this.isPerformanceMaxCampaign ? 'Asset Groups' : 'Ads'
    },
    showColumnsText: function () {
      return this.isPerformanceMaxCampaign ? 'Asset Groups' : 'Ad Groups'
    },
    showFinalUrl () {
      return this.selectedCampaignLauncherConfig.campaign.type != this.adwordsEnums.campaignType["App"] || this.selectedCampaignLauncherConfig.campaign.subtype != adwordsEnums.campaignSubType['App Installs']
    },
    showAdGroupsColumns: function () {
      if (this.isDisplayCampaign || this.isAppCampaign || this.isDemandGenCampaign) {
        return adwordsEnums.adgroupColumns.slice(0, 2);
      }
      return adwordsEnums.adgroupColumns
    }
  },
  methods: {
    ...mapMutations([
      "set_editedCampaignBid"
    ]),
    getAdExtensions (adGroupFeedExtensions, adgroupAssets) {
      var adExtensions = []
      for (let adGroupFeedExtension of adGroupFeedExtensions) {
        adExtensions.push(adwordsEnums.feedItemAdExtensionType[adGroupFeedExtension.extensionType])
      }
      for (let adgroupAsset of adgroupAssets) {
        adExtensions.push(adwordsEnums.assetType[adgroupAsset.fieldType])
      }
      return adExtensions && adExtensions.length > 0 ? [...new Set(adExtensions)].join(", ") : "-"
    },
    getNegativeKeywordsCount (isNegativeKeywordExported, negativeKeywordListIds) {
      if (this.viewType == "Changes") {
        return isNegativeKeywordExported ? 0 : negativeKeywordListIds.length;
      }
      return negativeKeywordListIds.length;
    },
    toggleAdgroup (id) {
      const index = this.expandedCampaignRows.indexOf(id);
      if (index > -1) {
      	this.expandedCampaignRows.splice(index, 1)
      } else {
      	this.expandedCampaignRows.push(id)
      }
    },
    toggleAd (id) {
      const index = this.expandedadgroupRows.indexOf(id);
      if (index > -1) {
      	this.expandedadgroupRows.splice(index, 1)
      } else {
        this.expandedadgroupRows = [];
      	this.expandedadgroupRows.push(id)
      }
    },
    adwordsAdgroups (adgroups) {
      if (this.viewType == "Changes") {
        let newAdGroups = [];
        for (let i = 0; i < adgroups.length; i++) {
          if (!adgroups[i].id) {
            adgroups[i].ads = this.adwordsAds(adgroups[i].ads);
            adgroups[i].adExtensions = this.getAdExtensions(adgroups[i].adGroupFeedExtensions, adgroups[i].adgroupAssets);
            newAdGroups.push(adgroups[i]);
          } else {
            let adgroupCopy = JSON.parse(JSON.stringify(adgroups[i]));
            adgroupCopy.keywords = adgroupCopy.keywords.filter(k => !k.id);
            adgroupCopy.adgroupAssets = adgroupCopy.adgroupAssets.filter(e => !e.id);
            adgroupCopy.adGroupFeedExtensions = adgroupCopy.adGroupFeedExtensions.filter(e => !e.id);
            adgroupCopy.adExtensions = this.getAdExtensions(adgroupCopy.adGroupFeedExtensions, adgroupCopy.adgroupAssets);
            adgroupCopy.ads = adgroupCopy.ads.filter(a => !a.id);
            adgroupCopy.ads = this.adwordsAds(adgroupCopy.ads);
            if (adgroupCopy.keywords.length > 0 || adgroupCopy.ads.length > 0 || adgroupCopy.adExtensions != '-') {
              newAdGroups.push(adgroupCopy);
            }
          }
        }
        return newAdGroups;
      } else {
        for (let i = 0; i < adgroups.length; i++) {
          adgroups[i].ads = this.adwordsAds(adgroups[i].ads);
          adgroups[i].adExtensions = this.getAdExtensions(adgroups[i].adGroupFeedExtensions, adgroups[i].adgroupAssets);
        }
      }
      return adgroups;
    },
    adwordsAssetGroups (assetGroups) {
      if (this.viewType == "Changes") {
        return assetGroups.filter(asg => asg.processingStatus != adwordsEnums.exportStatus.Success)
      }
      return assetGroups;
    },
    adwordsCampaigns () {
      if (JSON.stringify(this.adwordsEntitiesResponse) === "{}") return []
      var adwordsCampaigns = this.adwordsEntitiesResponse.campaigns;
      if (this.viewType == "Changes") {
        return adwordsCampaigns.filter(c => !c.id);
      }
      return adwordsCampaigns;
    },
    adwordsAds (ads) {
      let adsToDisplay = [];
      for (let i = 0; i < ads.length; i++) {
        var adToDisplay = {};
        // handle final url for app campaign
        if (!this.isAppCampaign) {
          let finalUrl = ads[i].finalUrls[0];
          let baseUrl = new URL(finalUrl);
          let displayUrl = baseUrl.protocol + '//' + baseUrl.hostname;
          if (typeof ads[i].displayPaths !== 'undefined') {
            for (let j = 0; j < 2; j++) {
              if (typeof ads[i].displayPaths[j] !== 'undefined') {
                displayUrl += '/' + ads[i].displayPaths[j];
              }
            }
          }
          adToDisplay.landingPage = finalUrl;
          adToDisplay.displayUrl = displayUrl;
        }
        adToDisplay.ad = ads[i].headlines[0].text;
        adsToDisplay.push(adToDisplay);
      }
      return adsToDisplay;
    },
    findByValue (obj, findValue) {
      return Object.entries(obj).find(([key, val]) => val == findValue)[0]
    },
    verifyAll () {
      this.$emit("elementsVerified", [
        { value: this.budgetTypeVerified, label: "Campaign Setup" },
        { value: this.targetsVerified, label: "Targets" },
        { value: this.locationsVerified, label: "Locations" },
        { value: this.excludedLocationsVerified, label: "Excluded Locations" },
        { value: this.networksVerified, label: "Networks" },
        { value: this.assetGroupsVerified, label: "Asset Groups" }
      ]);
    },
    formatLocations (locationString) {
      var additionalLocations = []
      var locationsObject = JSON.parse(locationString);
      if (locationsObject != null && locationsObject.GeoCoordinates != null && locationsObject.GeoCoordinates.length > 0) {
        locationsObject.GeoCoordinates.forEach(location => {
          var radius = `(${location["Radius"]} ${adwordsEnums.distanceUnit[location["RadiusUnit"]]} radius)`;
          var locationString = `(latitude:${location["Latitude"]}, longitude:${location["Longitude"]}) ${radius}`;
          additionalLocations.push(locationString)
        })
      }
      return additionalLocations.join(' ,')
    },
    getEmbedYoutubeLink (url) {
      let embedUrl;
      if (url.includes('watch?v=')) {
        embedUrl = url.replace('watch?v=', 'embed/');
      } else if (url.includes('shorts/')) {
        embedUrl = url.replace('shorts/', 'embed/');
      } else if (url.includes('youtu.be/')) {
        embedUrl = url.replace('youtu.be/', 'youtube.com/embed/');
      } else {
        embedUrl = url;
      }

      embedUrl = embedUrl.split('?')[0];
      return embedUrl;
    },
    getRowSpanAssetGroupTable (record) {
      switch (this.selectedCampaignLauncherConfig.campaign.type) {
        case adwordsEnums.campaignType["App"]:
          let rowsSpanCount = this.adwordsEnums.assetsSupportedByCampaignType[this.selectedCampaignLauncherConfig.campaign.type].length;
          if (this.selectedCampaignLauncherConfig.campaign.subtype == adwordsEnums.campaignSubType["App Installs"]) {
            if (record.youtubeVideos.length) rowsSpanCount++;
            if (record.images.length) rowsSpanCount++;
            return rowsSpanCount;
          }
          if (this.selectedCampaignLauncherConfig.campaign.subtype == adwordsEnums.campaignSubType["App Engagement"]) {
            rowsSpanCount++;
            if (record.youtubeVideos.length) rowsSpanCount++;
            if (record.images.length) rowsSpanCount++;
            return rowsSpanCount;
          }
          break;

        case adwordsEnums.campaignType["Display"]:
          let rowSpanCounts = this.adwordsEnums.assetsSupportedByCampaignType[this.selectedCampaignLauncherConfig.campaign.type].length;
          if (record.youtubeVideos && record.youtubeVideos.length) {
            rowSpanCounts++;
          }
          return rowSpanCounts;

        case adwordsEnums.campaignType["Demand Gen"]:
          let rowSpanCount = this.adwordsEnums.assetsSupportedByCampaignType[this.selectedCampaignLauncherConfig.campaign.type].length;
          if (record.youtubeVideos && record.youtubeVideos.length) {
            rowSpanCount++;
          } else if (record.cardsInfo && record.cardsInfo.length) {
            rowSpanCount += record.cardsInfo.length
          }
          return rowSpanCount;

        case adwordsEnums.campaignType["Performance Max"]:
          let rowSpanCountsForPmax = this.adwordsEnums.assetsSupportedByCampaignType[this.selectedCampaignLauncherConfig.campaign.type].length;
          if (record.callToAction) {
            rowSpanCountsForPmax++;
          }
          if (record.searchThemes && record.searchThemes.length) {
            rowSpanCountsForPmax++;
          }
          if (record.youtubeVideos && record.youtubeVideos.length) {
            rowSpanCountsForPmax++;
          }

          return rowSpanCountsForPmax;

        default:
          return record.youtubeVideos.length > 0 ? 8 : 7;
      }
    },
    getCampaignBid (record) {
      switch (record.bidStrategyType) {
        case adwordsEnums.adwordsBiddingStrategyTypes.TargetCpa:
          return record.targetBidAmount;
        case adwordsEnums.adwordsBiddingStrategyTypes.MaximizeClicks:
          return record.maxCpcLimit;
        case adwordsEnums.adwordsBiddingStrategyTypes.MaximizeConversions:
          return record.targetBidAmount;
        case adwordsEnums.adwordsBiddingStrategyTypes.TargetImpressionShare:
          return record.maxCpcLimit;
      }
    },
    isBidSupported (bidStrategyType) {
      return [adwordsEnums.adwordsBiddingStrategyTypes.TargetCpa, adwordsEnums.adwordsBiddingStrategyTypes.MaximizeClicks, adwordsEnums.adwordsBiddingStrategyTypes.MaximizeConversions, adwordsEnums.adwordsBiddingStrategyTypes.TargetImpressionShare].includes(bidStrategyType);
    },
    enableBidEdit (id) {
      this.tempBid[id] = this.bid[id];
      this.isBidEditEnabled[id] = true;
    },
    cancelBid (id) {
      this.tempBid[id] = this.bid[id];
      this.isBidEditEnabled[id] = false;
    },
    applyBid (id) {
      this.bid[id] = this.tempBid[id] && this.tempBid[id] != 0 ? this.tempBid[id] : null;
      let editedBid = this.editedCampaignBid;
      editedBid[id] = this.bid[id];
      this.set_editedCampaignBid(editedBid);
      this.isBidEditEnabled[id] = false;
    },
    validateInput (e) {
      if (e.target.value && e.target.value == 0) {
        this.$Message.destroy();
        this.$Message.error('The value must be greater than 0');
      }
    }
  },
  watch: {
    budgetTypeVerified () {
      this.verifyAll()
    },
    targetsVerified () {
      this.verifyAll()
    },
    locationsVerified () {
      this.verifyAll()
    },
    excludedLocationsVerified () {
      this.verifyAll()
    },
    networksVerified () {
      this.verifyAll()
    },
    assetGroupsVerified () {
      this.verifyAll()
    },
    viewType () {
      if (this.campaignPivot) {
        this.expandedCampaignRows = [];
        for (let i = 0; i < this.campaignPivot.campaigns; i++) {
          this.expandedCampaignRows.push(i);
        }
      }
    }
  }
};
</script>

<style scoped>
.title-sticky {
  padding: 0px 5px;
  top: 100px;
  background: white;
  z-index: 10;
  border: 1px solid #eee;
}
.element-label {
  font-size: large;
  padding: 10px 10px 10px 0;
}
.verified-action {
  float: right;
  padding: 10px;
}
.checkbox-status-label {
  padding-right: 5px;
  color: #999;
  cursor: pointer;
}
input[type="checkbox"] {
  vertical-align: text-bottom;
}
.total-footer {
  font-weight: 600;
  background-color: #eee;
}
.table-footer-label {
  border: 1px solid #ddd;
  padding: 0px 5px;
}
.target-label {
  padding-top: 10px;
}
.target-sub-label {
  padding-top: 10px;
  font-size: 0.9em;
  color: #979898;
  max-width: 420px;
}
.page-label {
  padding: 10px 5px;
  top: 140px;
  background: white;
  border: 1px solid #eee;
}
.creatives-li {
  padding-bottom: 5px;
  list-style: none;
}
.more-previews {
  font-size: 1.3em;
}
.data-right {
  text-align: right;
}
.data-center {
  text-align: center;
  vertical-align: middle;
}
.upper-case {
  text-transform: uppercase;
}
::v-deep .ivu-modal-footer {
  display: none;
}
.table th {
  border:1px solid #ddd;
  padding:10px
}
.campaign-brief-row:hover, .adgroup-brief-row:hover {
  background-color: #f6f7f8 !important;
  cursor: pointer;
}
.image-asset {
  padding-right: 10px;
}
.extraAdName {
 color: #979898;
}
.adName {
 font-style: italic;
}
.card-text {
  font-weight: bold;
}
::v-deep .edit-bid-input .ivu-input-small {
  border: 0px;
  border-bottom: 0.3px solid #566464;
  box-shadow: none;
  width: 70px;
}
.edit-bid-input {
  width: 50%;
  border: 0px;
}
::v-deep .ivu-input-small {
  padding: 2px 0px;
  height: 17px;
  border-radius: 0px;
}
</style>
